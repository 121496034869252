import Cookies from "js-cookie";
import actions from "./actions";
import { DataService } from "../../config/dataService/dataServiceEcommerce";
import { DataService as DataServiceAdmin } from "../../config/dataService/dataService";

const {
  readProfileCustomerSuccess,
  tokenSuccess,
  saveSuccess,
  cleanBuyAction,
  shipmentsSuccess,
  categoriesSuccess,
  productsSuccess,
  cleanProductAction,
  readProfileSuccess,
  productSuccess,
  actionBegin,
  error,
  saveJobSession,
  taxSuccess,
  actionResponseStatus,
  actionCleanResponseStatus,
  addressBooksSuccess,
  addressBookSuccess,
  cleanAddressBookAction,
  cleanProfileCustomerEcommerceAction,
  getOrdersAction,
  getOrderAction,
  cleanOrdersAction,
  cleanShipmentsAction,
  cleanTaxesAction,
} = actions;

/*=========== Banking Books */
const getFormatAccount = (institution_id, bank_name) => {
  return async (dispatch) => {
    try {
      const response = await DataService.getAsCustomer(
        "/api/banks-format?institution_id=" +
          institution_id +
          "&bank_name=" +
          bank_name
      );

      if (response.status >= 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  };
};

const getBankingCatalog = (text) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingBanking: true }));
      const response = await DataService.getAsCustomer(
        "/api/banks?" +
          (parseInt(text, 10) ? "transit_ins=" : "bank_name=") +
          text
      );

      if (response.status >= 200) {
        dispatch(actionBegin({ loadingBanking: false }));
        return response.data;
      } else {
        dispatch(error("Error from API"));
        return [];
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return [];
    }
  };
};

const getBanksCustomer = (page = 1) => {
  return async (dispatch) => {
    try {
      const response = await DataService.getAsCustomer(
        "/api/users/banks?page=" + page
      );

      if (response.status >= 200) {
        return response.data;
      } else {
        dispatch(error("Error from API"));
        return [];
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return [];
    }
  };
};

const saveBankCustomer = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.postAsCustomer(
        "/api/users/banks",
        data
      );

      if (response.status >= 200) {
        return response.data.data;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const updateBankCustomer = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.putAsCustomer(
        "/api/user_accounts/" + data.id,
        data
      );

      if (response.status >= 200) {
        return true;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const deleteBankCustomer = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.putAsCustomer(
        "/api/user_accounts/" + data.id,
        { status: 2 }
      );

      if (response.status >= 200) {
        return true;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

/*=========== Company Books */
const getCompanyCustomerById = (id) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingCompany: true }));
      const response = await DataService.getAsCustomer(
        "/api/users/companies/" + id
      );

      if (response.status >= 200) {
        dispatch(actionBegin({ loadingCompany: false }));
        return response.data;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const getCompaniesByCustomer = (page = 1, id) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingCompany: true }));
      const response = await DataServiceAdmin.get(
        `/api/users/companiesCustomer/${id}?page=` + page
      );

      if (response.status >= 200) {
        dispatch(actionBegin({ loadingCompany: false }));
        return response.data;
      } else {
        dispatch(error("Error from API"));
        return [];
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return [];
    }
  };
};

const getCompanyCustomer = (page = 1) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingCompany: true }));
      const response = await DataService.getAsCustomer(
        "/api/users/companies?page=" + page
      );

      if (response.status >= 200) {
        dispatch(actionBegin({ loadingCompany: false }));
        return response.data;
      } else {
        dispatch(error("Error from API"));
        return [];
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return [];
    }
  };
};

const saveCompanyCustomer = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingCompany: true }));
      const response = await DataService.postAsCustomer(
        "/api/users/companies",
        data
      );

      if (response.status >= 200) {
        dispatch(actionBegin({ loadingCompany: false }));
        return response.data.data;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const updateCompanyCustomer = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingCompany: true }));
      const response = await DataService.putAsCustomer(
        "/api/users/companies/" + data.id,
        data
      );

      if (response.status >= 200) {
        dispatch(actionBegin({ loadingCompany: false }));
        return true;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

/*=========== Address Books */
const getAddressBooks = (page, source = "customer", customerId = "") => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingAddress: true }));
      let response = null;
      if (source === "customer")
        response = await DataService.getAsCustomer(
          "/api/address_books" + (page ? "?page=" + page : "")
        );
      if (source === "admin")
        response = await DataServiceAdmin.get(
          "/api/address_books?customerId=" +
            customerId +
            (page ? "&page=" + page : "")
        );

      if (response.status >= 200) {
        const payload = { addressBooks: response.data };
        dispatch(addressBooksSuccess(payload));
        return response.data;
      }
      dispatch(error("Error from API"));

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const getAddressBookById = (id) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingAddress: true }));
      const response = await DataService.getAsCustomer(
        "/api/address_books/" + id
      );
      if (response.status >= 200) {
        dispatch(addressBookSuccess({ addressBook: response.data.data }));
      } else {
        dispatch(error("Error from API"));
      }

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const saveAddressBook = (addressBook, source = "customer") => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingAddress: true }));
      let response = null;
      if (source === "customer")
        response = await DataService.postAsCustomer(
          "/api/address_books",
          addressBook
        );
      if (source === "admin")
        response = await DataServiceAdmin.post(
          "/api/address_books",
          addressBook
        );

      if (response.status >= 200) {
        dispatch(actionBegin({ loadingAddress: false }));
        return response.data.data;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const updateAddressBook = (addressBook) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingAddress: true }));
      const response = await DataService.putAsCustomer(
        "/api/address_books/" + addressBook.id,
        addressBook
      );
      if (response.status >= 200) {
        dispatch(actionBegin({ loadingAddress: false }));
        return response.data.data;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const deleteAddressBook = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete("/api/address_books/" + id);
      if (response.status >= 200) {
        dispatch(getAddressBooks(1));
        return true;
      } else {
        dispatch(error("Error from API"));
      }

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const cleanAddressBook = () => {
  return async (dispatch) => dispatch(cleanAddressBookAction());
};

/*=========== Profile */
const signInReseller = (params) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingUser: true }));
      const response = await DataService.post("/api/login-reseller", params);
      if (response.status === 200) {
        sessionStorage.setItem("reseller", response.data.data.token);

        dispatch(tokenSuccess(response.data.data.token));
        dispatch(readProfile());
        return true;
      } else {
        dispatch(error("Error from API"));
      }

      return false;
    } catch (err) {
      dispatch(error(err.toString()));

      return false;
    }
  };
};

const readProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingUser: true }));
      const response = await DataService.get("/api/ecommerce/user_details");
      if (response.status === 200) {
        dispatch(readProfileSuccess(response.data.data));
      } else {
        dispatch(error("Error from API"));
      }
    } catch (err) {
      dispatch(error(err.toString()));
    }
  };
};

/*=========== Orders */
const saveOrder = (order) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post("/api/ecommerce/order", order);
      if (response.status >= 200) {
        // console.log(response);
      } else {
        dispatch(error("Error from API"));
      }

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

/*=========== Categories */
const getCategories = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get("/api/ecommerce/categories");
      if (response.status >= 200) {
        const payload = { categories: response.data };
        dispatch(categoriesSuccess(payload));
      } else {
        dispatch(error("Error from API"));
      }

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

/*=========== Products */
const getProducts = (page, categoryId, isMain, value) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingProduct: true }));
      const response = await DataService.get(
        "/api/ecommerce/products?page=" +
          page +
          (value ? "&value=" + value : "") +
          (categoryId ? "&categoryId=" + categoryId : "") +
          (isMain ? "&isMain=" + isMain : "")
      );

      if (response.status >= 200) {
        const payload = { products: response.data };
        dispatch(productsSuccess(payload));
        return response.data;
      } else {
        dispatch(error("Error from API"));
      }

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const getProductById = (product_id) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingProduct: true }));
      const response = await DataService.get(
        "/api/ecommerce/products/" + product_id
      );
      if (response.status >= 200) {
        dispatch(
          productSuccess({
            product: response.data.data.products,
            software: response.data.data.software,
          })
        );
      } else {
        dispatch(error("Error from API"));
      }

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const getProductByIdObject = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get("/api/ecommerce/products/" + id);
      if (response.status >= 200) {
        return response.data;
      } else {
        dispatch(error("Error from API"));
      }

      return false;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const createShipments = (order_id, address_id, contact_number) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingTax: true }));
      const response = await DataService.postAsCustomer(
        "/api/ecommerce/shipments",
        { order_id, address_id, contact_number }
      );
      if (response.status >= 200) {
        dispatch(shipmentsSuccess(response.data));
      } else {
        dispatch(error("Error from API"));
      }

      dispatch(actionBegin({ loadingTax: false }));
      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const submitCoupon = (name) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingCoupon: true }));
      const response = await DataService.putAsCustomer("/api/apply-coupon", {
        name,
      });
      if (response.status >= 200) {
        dispatch(actionBegin({ loadingCoupon: false }));
        return response.data;
      } else {
        dispatch(error("Error from API"));
      }

      return false;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const changeLanguageCustomer = (language) => {
  return async (dispatch) => {
    try {
      const response = await DataService.putAsCustomer("/api/users/language", {
        language,
      });
      if (response.status >= 200) {
        return true;
      } else {
        dispatch(error("Error from API"));
      }

      return false;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const updateRate = (shipping_rate_information, order_id) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingTax: true }));
      const response = await DataService.putAsCustomer(
        "/api/order/select-rate",
        { shipping_rate_information, order_id }
      );
      if (response.status >= 200) {
        return true;
      }
      dispatch(actionBegin({ loadingTax: false }));
      return false;
    } catch (err) {
      dispatch(actionBegin({ loadingTax: false }));
      return false;
    }
  };
};

const createTax = (address_id, order_id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.postAsCustomer("/api/order/tax", {
        address_id,
        order_id,
      });
      if (response.status >= 200) {
        dispatch(taxSuccess(response.data.taxes));
      } else {
        dispatch(error("Error from API"));
      }

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const createBuy = () => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingBuy: true }));
      const response = await DataService.postAsCustomer(
        "/api/ecommerce/shipments/label/payment",
        {}
      );
      if (response.status >= 200) {
        dispatch(saveSuccess(response.data));
      } else {
        dispatch(error("Error from API"));
      }

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const cleanShipments = () => {
  return async (dispatch) => dispatch(cleanShipmentsAction());
};

const cleanTaxes = () => {
  return async (dispatch) => dispatch(cleanTaxesAction());
};

const cleanBuy = () => {
  return async (dispatch) => dispatch(cleanBuyAction());
};

const cleanProduct = () => {
  return async (dispatch) => dispatch(cleanProductAction());
};

const createJobAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.postCpress("/api/jobs", data);
      if (response.status >= 200) {
        const responseData = response.data;
        const inFifteenMinutes = new Date(
          new Date().getTime() + 15 * 60 * 1000
        );
        Cookies.set("cf_product_job_session", responseData.id, {
          expires: inFifteenMinutes,
        });

        dispatch(saveJobSession(responseData));   
        return responseData.id;     
      } else {
        dispatch(error("Error from Cpress API"));
      }

      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const getJobAction = (jobId) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingProduct: true }));
      const response = await DataService.getCpress(`/api/jobs/${jobId}`);
      if (response.status >= 200) {
        console.log("getJobAction.response: ", response.data);
        dispatch(saveJobSession(response.data));
      } else {
        dispatch(error("Error from API"));
      }

      return response.data;
    } catch (err) {
      console.log("err.toString(): ", err.toString());
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const getPreviewJobAction = (jobId, mode, method) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingProduct: true }));
      const response = await DataService.getCpress(
        `/api/jobs/${jobId}/${mode}/${method}`
      );
      if (response.status >= 200) {
        dispatch(saveJobSession(response.data));
      } else {
        dispatch(error("Error from API"));
      }

      return response.data;
    } catch (err) {
      console.log("err.toString(): ", err.toString());
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const updateJobAction = (jobId, data) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingCpress: true }));
      const response = await DataService.putCpress(`/api/jobs/${jobId}`, data);
      if (response.status >= 200) {
        const responseData = response.data;
        dispatch(saveJobSession(responseData));

        setTimeout(() => {
          dispatch(actionBegin({ loadingCpress: false }));
        }, 3000);
        return true;
      } else {
        dispatch(error("Error from Cpress API"));
      }

      return false;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const setJobId = (jobId) => {
  return (dispatch) => {
    dispatch(saveJobSession(jobId));
  };
};

const manageOrder = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.postAsCustomer(
        "/api/ecommerce/manage",
        data
      );
      console.log(response);
      if (response.status >= 200) {
        console.log("manageOrder.response: ", response.data);
        const responseData = response.data;
        console.log("responseData: ", responseData);
      } else {
        dispatch(error("Error from ecommerce API"));
      }
      return response.statusText;
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const loginCustomerAction = (data) => {
  return async (dispatch) => {
    try {
      // dispatch(actionBegin({ loadingUser: true }));
      const response = await DataService.post("/api/users/login", data);
      console.log("loginCustomerAction.response: ", response.data);
      if (response.status === 200) {
        localStorage.setItem("cf_customer", response.data.data.token);
        return true;
      }
      dispatch(error("Error from API"));
    } catch (err) {
      dispatch(error(err.toString()));
    }
    return false;
  };
};

const registerCustomerAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingUser: true }));
      const response = await DataService.post(
        "/api/users/customer_signup",
        data
      );
      console.log("registerCustomerAction.response: ", response.data.data);
      if (response.status === 200) {
        localStorage.setItem("cf_customer", response.data.data.token);
        //dispatch(setCustomerId(response.data.data.id));
      } else {
        dispatch(error("Error from API"));
      }
    } catch (err) {
      dispatch(error(err.toString()));
    }
  };
};

const loginEasyCustomer = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingLogin: true }));
      // const response = await DataService.post('/api/users/easy-login', data);
      const response = await DataService.post(
        "/api/users/customer_signup",
        data
      );
      if (response.status === 200) {
        dispatch(actionBegin({ loadingLogin: false }));

        return true;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const validateCustomerEasyAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingLogin: true }));
      const response = await DataService.post("/api/users/easy-validate", data);

      if (response.status === 200) {
        localStorage.setItem("cf_customer", response.data.data.token);
        localStorage.setItem("cf_customerId", response.data.data.user.id);
        dispatch(actionBegin({ loadingLogin: false }));
        return true;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const readProfileCustomer = () => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingLogin: true }));
      const response = await DataService.getAsCustomer(
        "/api/users/user_details"
      );
      if (response.status === 200) {
        dispatch(readProfileCustomerSuccess(response.data.data));
      }
    } catch (err) {}
  };
};

const cleanProfileCustomer = () => {
  return async (dispatch) => dispatch(cleanProfileCustomerEcommerceAction());
};

const setResponseStatus = (status, response) => {
  return (dispatch) => {
    dispatch(actionResponseStatus({ status, response }));
  };
};

const cleanResponseStatus = () => {
  return (dispatch) => {
    dispatch(actionCleanResponseStatus());
  };
};

const addProductToCart = (data) => {
  /*
  customer_id: dsfd-sdfsdf-sdfsdfsdf-dfsf, ((session storage))
  product_id: 1,
  quantity: 1,
  size: 200,
  template
  */
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingBuy: true }));
      const response = await DataService.postAsCustomer("/api/orders", data);
      if (response.status === 200) {
        setTimeout(() => {
          dispatch(actionBegin({ loadingBuy: false }));
        }, 1000);
        return response.data.data;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const cartCheckout = (data) => {
  /*
  customer_id: dsfd-sdfsdf-sdfsdfsdf-dfsf, ((session storage))
  shipping: {},
  payment: {},
  */
  return async (dispatch) => {
    try {
      // dispatch(actionBegin({ loadingLogin: true }));
      console.log("doing checkout");
      const response = await DataService.postAsCustomer(
        "/api/orders/checkout",
        data
      );
      if (response.status === 200) {
        return response.data.data;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const getOrder = (orderId) => {
  /*
  customer_id: dsfd-sdfsdf-sdfsdfsdf-dfsf, ((session storage))
  shipping: {}, 
  payment: {},
  */
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingOrder: true }));
      const response = await DataService.getAsCustomer(
        "/api/orders/" + orderId
      );
      if (response.status === 200) {
        dispatch(getOrderAction(response.data.data));
        return response.data.data;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const getOrderByCustomer = (user_id) => {
  return async (dispatch) => {
    try {
      const response = await DataServiceAdmin.get(
        "/api/orders/customer-array?user_id=" + user_id
      );
      if (response.status === 200) {
        return response.data;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const removeProductFromCart = (id) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingOrder: true }));
      const response = await DataService.putAsCustomer("/api/order/product", {
        id,
      });
      if (response.status === 200) {
        dispatch(getOrderHistory());

        setTimeout(() => {
          dispatch(actionBegin({ refreshShipping: true }));
        }, 500);
        return true;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const updateProductInCart = (data, source = "customer") => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingBuy: true }));
      let response = null;
      if (source === "customer")
        response = await DataService.putAsCustomer("/api/order/template", data);
      if (source === "admin")
        response = await DataServiceAdmin.put("/api/order/template", data);
      if (response.status === 200) {
        setTimeout(() => {
          dispatch(actionBegin({ loadingBuy: false }));
        }, 1000);
        return response.data;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const getOrderHistory = () => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingOrder: true }));
      if (sessionStorage.getItem("cf_orderIdEditByAdmin")) {
        dispatch(getOrder(sessionStorage.getItem("cf_orderIdEditByAdmin")));
      } else {
        const response = await DataService.getAsCustomer(
          "/api/orders/customer"
        );
        if (response.status === 200) {
          dispatch(getOrdersAction(response.data.data));
          return response.data;
        }
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const cleanOrders = () => {
  return (dispatch) => {
    dispatch(cleanOrdersAction());
  };
};

const reOrder = (id) => {
  /*
  id: dsfd-sdfsdf-sdfsdfsdf-dfsf 
  */
  return async (dispatch) => {
    try {
      const response = await DataService.postAsCustomer("/api/reorder", id);
      if (response.status === 200) {
        return response.data;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const registerUserByCode = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingLogin: true }));
      const response = await DataService.post(
        "/api/users/registerUserAndSendCode",
        data
      );
      if (response.status === 200) {
        dispatch(actionBegin({ loadingLogin: false }));
        return true;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const validateCode = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingLogin: true }));
      const response = await DataService.post("/api/users/validateCode", data);
      if (response.status === 200) {
        localStorage.setItem("cf_customer", response.data.data.token);
        localStorage.setItem("cf_customerId", response.data.data.user.id);
        dispatch(actionBegin({ loadingLogin: false }));
        return true;
      } else {
        dispatch(error("Error from API"));
        return false;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const validateBankAccountAdmin = (data) => {
  /*
  accountNumber: xxxxxx  
  */
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingLogin: true }));
      const response = await DataServiceAdmin.post(
        "/api/banks/validate-account",
        data
      );
      if (response.status === 200) {
        return response.data;
      }
      dispatch(error("Error from API"));
      dispatch(actionBegin({ loadingLogin: false }));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const validateBankAccount = (data) => {
  /*
  accountNumber: xxxxxx  
  */
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingLogin: true }));
      const response = await DataService.postAsCustomer(
        "/api/banks/validate-account",
        data
      );
      if (response.status === 200) {
        console.log("response.data: ", response);
        return response.data;
      }
      dispatch(error("Error from API"));
      dispatch(actionBegin({ loadingLogin: false }));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const getOrderPublic = (orderId) => {
  /*
  customer_id: dsfd-sdfsdf-sdfsdfsdf-dfsf, ((session storage))
  shipping: {}, 
  payment: {},
  */
  return async (dispatch) => {
    try {
      dispatch(actionBegin({ loadingOrder: true }));
      const response = await DataService.getAsCustomer(
        "/api/orders-public/" + orderId
      );
      if (response.status === 200) {
        dispatch(getOrderAction(response.data.data));
        return response.data.data;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const cartCheckoutPublic = (data) => {
  /*
  customer_id: dsfd-sdfsdf-sdfsdfsdf-dfsf, ((session storage))
  shipping: {},
  payment: {},
  */
  return async (dispatch) => {
    try {
      // dispatch(actionBegin({ loadingLogin: true }));
      console.log("doing checkout");
      const response = await DataService.postAsCustomer(
        "/api/orders/checkout_public",
        data
      );
      if (response.status === 200) {
        return response.data.data;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

const customerLoginAsAdminAction = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        `/api/users/custom-login/${id}`,
        {}
      );
      if (response.status === 200) {
        //alert(JSON.stringify(response.data.data));
        localStorage.setItem("cf_customer", response.data.data.token);
        localStorage.setItem("cf_customerId", response.data.data.user.id);
        sessionStorage.setItem("cf_onbehalfof", response.data.data.token);
        return true;
      }
      dispatch(error("Error from API"));
      return false;
    } catch (err) {
      dispatch(error(err.toString()));
      return false;
    }
  };
};

export {
  signInReseller,
  readProfile,
  cleanBuy,
  createBuy,
  getCategories,
  getProducts,
  getProductById,
  cleanProduct,
  createShipments,
  saveOrder,
  createJobAction,
  getJobAction,
  updateJobAction,
  getPreviewJobAction,
  setJobId,
  manageOrder,
  loginCustomerAction,
  registerCustomerAction,
  loginEasyCustomer,
  validateCustomerEasyAction,
  setResponseStatus,
  cleanResponseStatus,
  addProductToCart,
  getAddressBooks,
  getAddressBookById,
  saveAddressBook,
  updateAddressBook,
  deleteAddressBook,
  cleanAddressBook,
  cartCheckout,
  getBanksCustomer,
  getBankingCatalog,
  getOrder,
  readProfileCustomer,
  cleanProfileCustomer,
  saveBankCustomer,
  updateBankCustomer,
  deleteBankCustomer,
  removeProductFromCart,
  updateProductInCart,
  getOrderHistory,
  cleanOrders,
  reOrder,
  registerUserByCode,
  validateCode,
  createTax,
  validateBankAccount,
  getFormatAccount,
  updateRate,
  cleanShipments,
  cleanTaxes,
  getOrderPublic,
  cartCheckoutPublic,
  customerLoginAsAdminAction,
  getCompanyCustomerById,
  getCompanyCustomer,
  saveCompanyCustomer,
  updateCompanyCustomer,
  submitCoupon,
  actionBegin,
  getCompaniesByCustomer,
  getOrderByCustomer,
  validateBankAccountAdmin,
  changeLanguageCustomer,
  getProductByIdObject,
  
};
