const cf = {
  '*Please provide your phone number, to obtain the shipping rates':
    "*Veuillez fournir votre numéro de téléphone, pour obtenir les tarifs d'expédition",
  'To overwrite system generating account format you must upload a void cheque or direct deposit form':
    'Pour écraser le système générant le format de compte, vous devez télécharger un chèque annulé ou un formulaire de dépôt direct',
  'You have selected an account with the option to upload a void cheque':
    "Vous avez sélectionné un compte avec l'option de télécharger un chèque annulé",
  'Bank Province': 'Province de la Banque',
  'Company Province': "Province de l'entreprise",
  'Reverse Numbering': 'Numérotation inversée',
  'Bank Postal Code': 'Code postal de la banque',
  'Company Postal Code': "Code postal de l'entreprise",
  'VOID CHEQUE': 'CHÈQUE ANNULÉ',
  'Select Security Features': 'Sélectionnez les fonctions de sécurité',
  'SOFTWARE TYPE': 'TYPE DE LOGICIEL',
  Apply: 'Appliquer',
  Coupon: 'Coupon',
  'SELECT CHEQUE TYPE': 'SÉLECTIONNER LE TYPE DE CONTRÔLE',
  'LOG OUT': 'SE DÉCONNECTER',
  'Contact Phone': 'Numéro du contact',
  'Email to': 'Envoyer à',
  'Email to send your invoice': 'Email pour envoyer votre facture',
  'Follow us on': 'Suivez-nous sur',
  'Currency type': 'Type de devise',
  'Login/Register': 'Se connecter/Registre',
  Username: "Nom d'utilisateur",
  Password: 'Mot de passe',
  'Please input your username!': "Veuillez saisir votre nom d'utilisateur!",
  'Please input your password!': 'Veuillez saisir votre mot de passe!',
  'Sign in': "S'identifier",
  'Sign up': "S'inscrire",
  'Forgot your password?': 'Mot de passe oublié?',
  "Don't have an account?": "Vous n'avez pas de compte?",
  'Cheques Fulfillment is Payments Canada Self Accredited':
    'Cheques Fulfillment est auto-accrédité auprès par Paiements Canada',
  'Identity Assured up to $1.750,000': 'Identité assurée jusqu`à concurrence de 1.750,000 $',
  'Copyright © 2021 qplus. All rights reserved': 'Copyright © 2021 qplus. Tous les droits sont réservés',
  'Welcome to qplus.app': 'Bienvenue sur qplus.app',
  'Try our products risk-free. All of our products come with a 100% satisfaction guarantee.':
    'Essayez nos produits sans risque. Tous nos produits sont livrés avec une garantie de satisfaction à 100%.',
  'ORDER CHEQUES': 'COMMANDER DES CHÈQUES',
  'Buy now': 'Acheter maintenant',
  'Are you a returning customer?': 'Êtes-vous un client de retour?',
  'Re-order cheques now': 'Commander à nouveau des chèques maintenant',
  'Business cheques with an extra!': "Chèques d'affaires avec un supplément!",
  'qplus.app at your service!': 'qplus.app à votre service !',
  'Welcome to qplus.app, the cost-effective, friendly alternative to purchasing pre-printed computer cheques from financial institutions. We carry all of the popular styles of computer and business cheques that big banks do, and we use the same suppliers, allowing us to rival the quality of the products they sell. Our #1 priority is ensuring that your business and personal cheques are printed correctly and shipped quickly.':
    "Bienvenue sur qplus.app, l'alternative économique et conviviale à l'achat de chèques informatiques pré-imprimés auprès d'institutions financières. Nous offrons tous les styles populaires de chèques informatiques et commerciaux que font les grandes banques, et nous utilisons les mêmes fournisseurs, ce qui nous permet de rivaliser avec la qualité des produits qu'ils vendent. Notre priorité n°1 est de nous assurer que vos chèques professionnels et personnels sont imprimés correctement et expédiés rapidement.",
  'The pre-printed cheques offered by qplus.app are compliant with all of the guidelines established by the Canadian Payment Association, and since we use the same suppliers as banks, our cheques are of the same quality that companies receive when they place their initial order or re-orders with their financial institutions. We offer lighting fast delivery; typically, we can ship purchases placed before 1:30 p.m. on the same day as the order.':
    "Les chèques pré-imprimés offerts par qplus.app sont conformes à toutes les directives établies par l'Association Canadienne des Paiements, et puisque nous utilisons les mêmes fournisseurs que les banques, nos chèques sont de la même qualité que les entreprises reçoivent lors de leur commande initiale ou passer de nouvelles commandes auprès de leurs institutions financières. Nous offrons une livraison rapide d'éclairage; généralement, nous pouvons expédier les achats effectués avant 13h30 le jour même de la commande.",
  'Every order is processed with rush service standards, shipping and handling are free for all orders within Ontario and Quebec. Every order received at qplus.app is important, regardless of its size. Customer satisfaction is extremely important to us, and as such, we are available to assist our customers with selecting pre-printed computer cheques, with questions regarding how to order on our website, and with inquiries about orders that have already been placed, filled, or shipped. We can be reached by phone at 450.323.6244 or use our contact form.':
    "Chaque commande est traitée avec des normes de service urgentes, l'expédition et la manutention sont gratuites pour toutes les commandes en Ontario et au Québec. Chaque commande reçue sur qplus.app est importante, quelle que soit sa taille. La satisfaction du client est extrêmement importante pour nous et, à ce titre, nous sommes disponibles pour aider nos clients à sélectionner des chèques informatiques pré-imprimés, à poser des questions sur la manière de commander sur notre site Web et à poser des questions sur les commandes déjà passées, remplies, ou expédié. Nous sommes joignables par téléphone au 450.323.6244 ou utilisez notre formulaire de contact.",
  'Phone number:': 'Numéro de téléphone:',
  'Contact email:': 'Email du contact:',
  'North America Toll Free': 'Amérique du Nord sans frais',
  'For service in French': 'Pour le service en français',
  'Shopping cart': 'Panier',
  Login: 'Se connecter',
  'Please type your email!': 'Veuillez saisir votre email!',
  'Send link': 'Envoyer un lien',
  'START YOUR ORDER': 'COMMENCEZ VOTRE COMMANDE',
  Price: 'Prix',
  Cheques: 'Chèques',
  Personalization: 'Personnalisation',
  'Tax (GST/QST)': 'Taxe (TPS/TVQ)',
  Subtotal: 'Sous-total',
  'Pay now': 'Payez maintenant',
  STEP: 'ÉTAPE',
  'QUANTITY & COLOR': 'QUANTITÉ & COULEUR',
  PERSONALIZATION: 'PERSONNALISATION',
  'BANK INFORMATION': 'INFORMATIONS BANCAIRES',
  'ADDITIONAL OPTIONS': 'OPTIONS ADDITIONELLES',
  'Please type your email address to start your order':
    'Veuillez entrer votre adresse e-mail pour commencer votre commande:',
  'Your email': 'Votre email',
  'Select quantity': 'Sélectionnez la quantité:',
  'Select color': 'Choisissez la couleur:',
  Back: 'Retour',
  Finish: 'Terminer',
  Next: 'Suivant',
  'Choose the personalization font': 'Choisissez la police de personnalisation',
  'Name on account': 'Le nom sur le compte',
  City: 'Ville',
  State: 'Province',
  'ZIP code': 'Code postal',
  'Bank not selected': 'Banque non sélectionnée',
  'Select an available bank from the list': 'Sélectionnez une banque disponible dans la liste',
  'Account ending in': 'Compte se terminant par',
  Nickname: 'Surnom',
  'Choose from your existing accounts': 'Choisissez parmi vos comptes existants',
  OR: 'OU',
  'Add a bank account to your profile': 'Ajouter un compte bancaire à votre profil',
  'Type your 5-digit transit (branch) number to retrieve your bank details':
    'Saisissez votre numéro de transit (succursale) à 5 chiffres pour récupérer vos coordonnées bancaires',
  'Search here': 'Cherche ici',
  'You chose the following branch': 'Vous avez choisi la branche suivante',
  'Please type your 7 or 12 digit account number': 'Veuillez saisir votre numéro de compte à 7 ou 12 chiffres',
  'Please re-type your account number': 'Veuillez retaper votre numéro de compte',
  'The two numeric accounts you typed do not match!':
    'Les deux comptes numériques que vous avez saisis ne correspondent pas!',
  'Please type starting cheque number': 'Veuillez saisir le numéro de chèque de départ',
  'US dollar account?': 'Compte en dollars américains?',
  English: 'Anglais',
  French: 'Français',
  'Choose language': 'Choisissez la langue',
  'Please type your full name to start your order': 'Veuillez saisir votre nom complet pour commencer votre commande',
  'Full name': 'Nom et prénom',
  'Personal cheques': 'Chèques personnels',
  'Are you sure to delete this product?': 'Voulez-vous vraiment supprimer ce produit?',
  Product: 'Produit',
  Quantity: 'Quantité',
  'Make an order': 'Faire une commande',
  'Are you sure to send the order?': "Êtes-vous sûr d'envoyer la commande?",
  Size: 'Taille',
  Color: 'Couleur',
  'Create an account': 'Créer un compte',
  'Please, create your account': "S'il vous plaît, créez votre compte",
  'Email address': 'Adresse email',
  'Please, type a valid password. Minimum 6 characters long.':
    'Veuillez saisir un mot de passe valide. Minimum 6 caractères.',
  'Please, Type your full account number': 'Veuillez saisir votre numéro de compte complet',
  'Shipping address': 'Adresse de livraison',
  'Please, fill in your shipping address': 'Veuillez remplir votre adresse de livraison',
  'Contact name': 'Nom du contact',
  'Company name': "Nom de l'entreprise",
  Optional: 'Optionnel',
  'Phone number': 'Numéro de téléphone',
  'Country/Region': 'Pays/Région',
  'Please select': 'Veuillez sélectionner',
  'House number and street name': 'Numéro de maison et nom de la rue',
  'Apartment, suite, unit, etc.': 'Appartement, suite, unité, etc.',
  'Type your city': 'Tapez votre ville',
  'Type your ZIP code': 'Tapez votre code postal',
  'Payment method': 'Mode de paiement',
  'Please, select your payment method': 'Veuillez sélectionner votre mode de paiement',
  'Credit/debit card': 'Carte de crédit/débit',
  'Card number': 'Numéro de carte',
  'Expiration date': "Date d'expiration",
  YY: 'AA',
  'What is this?': "Qu'est-ce que c'est?",
  'Pay with PayPal': 'Payer avec PayPal',
  'Cash on delivery': 'Paiement à la livraison',
  'Order review': 'Examen de la commande',
  'Review and confirm order': 'Vérifier et confirmer la commande',
  'Shipping information': 'Informations sur la livraison',
  '+ Add new address': '+ Ajouter une nouvelle adresse',
  '+ Add new card': '+ Ajouter une nouvelle carte',
  Discount: 'Réduction',
  'Shipping cost': 'Frais de port',
  'Successful payment': 'Paiement réussi',
  'Thank you! We have received your payment': 'Merci! Nous avons reçu votre paiement',
  'Unselected shipping': 'Expédition non sélectionnée',
  'Select an available shipping from the list': 'Sélectionnez un envoi disponible dans la liste',
  'Order summary': 'Récapitulatif de la commande',
  'Select shipping': "Sélectionnez l'expédition",
  'day(s)': 'journée(s)',
  'Keep shopping': 'Continuer vos achats',
  'Add to cart': 'Ajouter au panier',
  'Update product': 'Réactualiser le produit',
  'Add product': 'Ajouter produit',
  'Add manual order': 'Ajouter une commande manuelle',
  'Create a new order': 'Créer une nouvelle commande',
  'This field is required': 'Ce champ est requis',
  'Business cheques': "Chèques d'entreprise",
  'Computer cheques': 'Chèques informatiques',
  'Manual cheques': 'Chéques manuals',
  'Blank cheques': 'Chèques en blanc',
  'Handwritten cheques': 'Chèques manuscrits',
  '2 copies (Carbon)': '2 exemplaires (Carbone)',
  'Starter kits': 'Kits de démarrage',
  Accessories: 'Accessoires',
  'Deposit slip': 'Bordereau de dépôt',
  'Fraud prevention pen': 'Stylo anti-fraude',
  'Customized deposit books': 'Livrets de dépôt personnalisés',
  'Customized deposit books for RBC': 'Livrets de dépôt personnalisés pour RBC',
  '2 books - $35.00': '2 livres - 35,00 $',
  '4 books - $49.00': '4 livres - 49,00 $',
  Add: 'Ajouter',
  'Account Edge computer deposit slips': 'Bordereaux de dépôt informatiques Account Edge',
  'Saved information': 'Informations enregistrées',
  'The following fields have errors': 'Les champs suivants contiennent des erreurs',
  'Commercial group': 'Groupe commercial',
  'Search by name': 'Rechercher par nom',
  'Add new commercial group': 'Ajouter un nouveau groupe commercial',
  'Add commercial group': 'Ajouter un groupe commercial',
  Name: 'Nom',
  Role: 'Rôle',
  'Commercial groups': 'Groupes commerciaux',
  User: 'Utilisateur',
  'Add user to commercial group': 'Ajouter un utilisateur au groupe commercial',
  'User to commercial group': 'Utilisateur à groupe commercial',
  'View all': 'Voir tout',
  'Add new': 'Ajouter nouveau',
  'Profile picture': 'Image de profil',
  'Type your name': 'Tapez votre nom',
  'example@gmail.com': 'exemple@gmail.com',
  Country: 'Pays',
  Bangladesh: 'Bengladesh',
  India: 'Inde',
  Dhaka: 'Dacca',
  'Type the company name': "Tapez le nom de l'entreprise",
  'Type the position': 'Tapez le position',
  'Join date': "Date d'adhésion",
  Status: 'Statut',
  Active: 'Actif',
  Inactive: 'Inactif',
  Blocked: 'Bloqué',
  'Loading...': 'Chargement...',
  Submit: 'Soumettre',
  'Update your registration': 'Mettre à jour votre inscription',
  'Are you sure to delete this?': 'Êtes-vous sûr de vouloir supprimer ceci?',
  'Data list': 'Liste de données',
  'Please enter the minimum content': 'Veuillez entrer le contenu minimum',
  'Post something': 'Poster quelque chose',
  'Type something...': 'Tapez quelque chose...',
  'Photo/Video': 'Photo/Vidéo',
  'Post it!': 'Postez-le!',
  Like: "J'aime",
  Reply: 'Réponse',
  'file uploaded successfully!': 'fichier téléchargé avec succès!',
  'file upload failed.': 'le téléchargement du fichier a échoué.',
  Delete: 'Supprimer',
  Share: 'Partager',
  'Type a comment...': 'Tapez un commentaire...',
  'Please type your comment!': 'Veuillez saisir votre commentaire!',
  'Send you a message': 'Vous envoyer un message',
  '5 hours ago': 'Il y a 5 heures',
  Hide: 'Cacher',
  'upload website template for sale': 'télécharger un modèle de site web à vendre',
  'has registered': "s'est inscrit",
  Print: 'Imprimer la facture',
  'Samsung Galaxy S8 256 GB': 'Samsung Galaxy S8 256 Go',
  $280: '280 $',
  '$38,536': '38 536 $',
  'Half sleeve shirt': 'Chemise à manches mi-longues',
  $25: '25 $',
  $32: '32 $',
  $950: '950 $',
  $985: '985 $',
  'Product name': 'Nom du produit',
  Sold: 'Vendu',
  Revenue: 'Revenu',
  'My Products': 'Mes produits',
  Friends: 'Amis',
  Follow: "S'abonner",
  Following: 'Abonné',
  'Load more friends': "Charger plus d'amis",
  Videos: 'Vidéos',
  'User bio': "Biographie de l'utilisateur",
  'Contact info': 'Informations de contact',
  'www.example.com': 'www.exemple.com',
  Skills: 'Compétences',
  'UI/UX': 'IU/EU',
  Branding: "l'image de marque",
  'Product design': 'La conception des produits',
  'Website design': 'Conception de site web',
  App: 'Application',
  'Social profiles': 'Profils sociaux',
  '$72,572': '72 572 $',
  'Total revenue': 'Revenu total',
  Orders: 'Ordres',
  Products: 'Produits',
  Overview: 'Aperçu',
  Timeline: 'Chronologie',
  Activity: 'Activité',
  'Change cover': 'Changer de couverture',
  Success: 'Succès',
  'Customer updated successfully!': 'Client mis à jour avec succès!',
  'Customer added successfully!': 'Client ajouté avec succès!',
  'First name': 'Prénom',
  'First name is required': 'Le prénom est requis',
  'Last name': 'Nom de famille',
  'Last name is required': 'Le nom de famille est requis',
  Gender: 'Genre',
  'Gender is required': 'Le genre est requis',
  'Please select a gender': 'Veuillez sélectionner un genre',
  Male: 'Mâle',
  Female: 'Femelle',
  Other: 'Autre',
  'Please type a valid email!': 'Veuillez saisir un e-mail valide!',
  Language: 'Langue',
  'Language is required': 'La langue est requise',
  'Please select a language': 'Veuillez sélectionner une langue',
  Reseller: 'Revendeur',
  'Reseller is required': 'Le revendeur est requis',
  'Please select a reseller': 'Veuillez sélectionner un revendeur',
  'No reseller found': 'Aucun revendeur trouvé',
  'Update customer': 'Mettre à jour le client',
  'Create customer': 'Créer un client',
  Customers: 'Clients',
  '+ Add new': '+ Ajouter nouveau',
  'Customer deleted successfully!': 'Client supprimé avec succès!',
  Error: 'Erreur',
  'Oops, we are having trouble loading clients': 'Oups, nous rencontrons des difficultés pour charger les clients',
  of: 'sur',
  items: 'articles',
  'No data found. Please search for a customer.': 'Aucune donnée disponible. Veuillez rechercher un client.',
  Yes: 'Oui',
  No: 'Non',
  'Do you want to edit this product?': 'Voulez-vous modifier ce produit?',
  'United States': 'États-Unis',
  'Something went wrong processing your order': "Une erreur s'est produite lors du traitement de votre commande",
  'Please contact us if you need help': "Veuillez nous contacter si vous avez besoin d'aide",
  'Send to': 'Envoyer à',
  'Shipping location': "Lieu d'expédition",
  'Modify shipping addresses': 'Modifier les adresses de livraison',
  'We are ready to start your order': 'Nous sommes prêts à commencer votre commande',
  'Please, click the link we send you with your login credentials':
    'Veuillez cliquer sur le lien que nous vous envoyons avec vos identifiants de connexion',
  'Please, provide your email and we will send you a login link':
    "S'il vous plaît, fournissez votre email et nous vous enverrons un lien de connexion",
  'It is not a valid email!': "Ce n'est pas un email valide!",
  Blue: 'Bleu',
  None: 'Aucun',
  Help: 'Aider',
  Welcome: 'Bienvenu(e)',
  'Do you want to': 'Voulez-vous',
  'sign out?': 'vous déconnecter?',
  'Your last 3 orders': 'Vos 3 dernières commandes',
  Order: 'Commande',
  'Qty.': 'Qté.',
  'Do you want to order this again?': 'Voulez-vous le commander à nouveau?',
  'Re-order now': 'Re-commandez maintenant',
  'My addresses': 'Mes adresses',
  'Delivery address book': "Carnet d'adresses de livraison",
  'Please type your first name': 'Veuillez saisir votre prénom',
  Address: 'Adresse',
  'Please type your address': 'Veuillez saisir votre adresse',
  Street: 'Rue',
  'Please type your street': 'Veuillez saisir votre rue',
  'Address (line 2)': 'Adresse (ligne 2)',
  'Please type your country': 'Veuillez saisir votre pays',
  'Please type your state': 'Veuillez saisir votre état',
  'Country code': 'Code de pays',
  'State code': "Code d'état",
  'Please type your city': 'Veuillez saisir votre ville',
  'Please type your ZIP code': 'Veuillez saisir votre code postal',
  Cancel: 'Annuler',
  Update: 'Mise à jour',
  Save: 'Sauvegarder',
  'and continue': 'et continue',
  addresses: 'adresses',
  Select: 'Sélectionner',
  'Send code': 'Envoyer le code',
  'Please provide your phone number or e-mail and we will send you a code to login':
    'Veuillez fournir votre numéro de téléphone ou votre e-mail et nous vous enverrons un code pour vous connecter',
  'Phone number or Email': 'Numéro de téléphone ou e-mail',
  'please enter the code we have sent to': 'veuillez saisir le code que nous vous avons envoyé',
  'Did not you get the code?': "Vous n'avez pas reçu le code?",
  'New address': 'Nouvelle adresse',
  'When placing an order again, the prices will be updated and the availability of the products will be verified.':
    "Lors d'une nouvelle commande, les prix seront mis à jour et la disponibilité des produits sera vérifiée.",
  'Shipping discount': "Remise d'expédition",
  'Order now': 'Commandez maintenant',
  More: 'Plus',
  'resend code': 'renvoyer le code',
  'See my orders': 'Voir mes commandes',
  'Re-order': 'Re-commander',
  'Re-order and edit': 'Re-commander  et modifier',
  'Do you want to re-order and edit this?': 'Voulez-vous commander à nouveau et modifier ceci?',
  'Ship to': 'Envoyez à',
  'Change billing address': 'Modifier la adresse de facturation',
  'Billing address': 'Adresse de facturation',
  'Change shipping address': 'Changer la adresse de livraison',
  'Shipping Methods': 'Méthodes de livraison',
  'Firstname on card': 'Prénom sur la carte',
  'Lastname on card': 'Nom de famille sur la carte',
  'Pay with credit': 'Payer avec crédit',
  'Additional Options': 'Options additionelles',
  'Transit Days': 'Jours de transit',
  'Total Charge': 'Charge totale',
  'Postal code': 'Code postal',
  'Show cheque': 'Montrer chèque',
  'My books': 'Mes livres',
  'Address book': "Carnet d'adresses",
  'Bank book': 'Livret de banque',
  'Bank account': 'Compte bancaire',
  Customer: 'Client',
  'You have been requested to pay': 'Il vous a été demandé de payer',
  "What's in this order": "Qu'y a-t-il dans cette commande",
  'WE REQUIRE YOUR APPROVAL': 'NOUS AVONS BESOIN DE VOTRE APPROBATION',
  'By approving you are agreeing that all information is correct.':
    'En approuvant, vous acceptez que toutes les informations soient correctes.',
  'For these reasons, any refunds on customized products request after the approval request cannot be honored':
    "Pour ces raisons, tout remboursement sur demande de produits personnalisés après la demande d'approbation ne peut être honoré",
  'PLEASE CAREFULLY REVIEW YOUR DETAILS BEFORE SUBMITTING YOUR ORDER':
    'VEUILLEZ VÉRIFIER ATTENTIVEMENT VOS DÉTAILS AVANT DE SOUMETTRE VOTRE COMMANDE',
  'Your order has been sent': 'Votre commande a été envoyée',
  'Click on the logo in the navigation bar to continue shopping':
    'Cliquez sur le logo dans la barre de navigation pour continuer vos achats',

  'Voided Cheque Sample': 'Échantillon de chèque annulé',
  'Please select a option': 'Veuillez sélectionner une option',
  Upload: 'Télécharger',
  'I Do Not Have a Void Cheque': "Je n'ai pas de chèque annulé",
  'type in your postal code to populate your address': 'saisissez votre code postal pour renseigner votre adresse',
  'Addresses used on the check cannot be deleted or edited':
    'Les adresses utilisées sur le chèque ne peuvent pas être supprimées ou modifiées',
  proceed: "continuer l'édition",
  i_approve_it: "JE L'APPROUVE",
  'PLEASE CAREFULLY REVIEW YOUR DATA IS CORRECT': 'VEUILLEZ VÉRIFIER ATTENTIVEMENT QUE VOS DONNÉES SONT CORRECTES',
  confirm_changes: 'CONFIRMER LES MODIFICATIONS',
  'Pay by credit card': 'Payer par Carte de Crédit',
  'Pay by direct debit': 'Payer par Direct Debit',
  'Unselected bank account for debit': 'Compte bancaire non sélectionné pour le débit',
  'Select an available bank account from your list': 'Sélectionnez un compte bancaire disponible dans votre liste',
  'Tax info missing': 'Informations fiscales manquantes',
  'Your address is not complete, please update it before sending your order':
    "Votre adresse n'est pas complète, veuillez la mettre à jour avant d'envoyer votre commande",
  'Choose signature lines': 'Choisissez les lignes de signature',
  '1 Signature Required': '1 Signature requise',
  '2 Signature Lines': '2 Lignes de Signature',
  '3 Signature Lines': '3 Lignes de Signature',
  'Company Phone': "Téléphone de l'entreprise",
  'Custom Text Above Signature Line (optional)': 'Texte personnalisé au-dessus de la ligne de signature (optionnel)',
  'Custom Text Above Signature Line 2 (optional)':
    'Texte personnalisé au-dessus de la ligne de signature 2 (optionnel)',
  'type in or update your street name to populate your address':
    'saisissez ou mettez à jour votre nom de rue pour renseigner votre adresse',
  'Please verify your address is correct before saving the information':
    'Veuillez vous assurer que votre adresse est correcte avant de sauvegarder les informations',
  terms_conditions: "J'accepte les conditions d'utilisation",
  'Please retype your address in the searchbar': 'Veuillez rentrer votre adresse dans la barre de recherche',
};

const fr = {
  translation: {
    ...cf,
  },
};

export default fr;
